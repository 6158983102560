/**
 * @generated SignedSource<<aff48ec897d5796d2594b6d93ae13f79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageHeaderQuery$variables = {
  address: string;
  skip: boolean;
};
export type PageHeaderQuery$data = {
  readonly delegate?: {
    readonly " $fragmentSpreads": FragmentRefs<"ProfileDropDownFragment">;
  };
};
export type PageHeaderQuery = {
  response: PageHeaderQuery$data;
  variables: PageHeaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "addressOrEnsName",
    "variableName": "address"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tokenId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accessory",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "background",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "body",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "glasses",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "head",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TokenAmount",
    "kind": "LinkedField",
    "name": "amount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Noun",
  "kind": "LinkedField",
  "name": "nounsRepresented",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PageHeaderQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Delegate",
            "kind": "LinkedField",
            "name": "delegate",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileDropDownFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PageHeaderQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Delegate",
            "kind": "LinkedField",
            "name": "delegate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResolvedName",
                    "kind": "LinkedField",
                    "name": "resolvedName",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DelegateStatement",
                "kind": "LinkedField",
                "name": "statement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Noun",
                "kind": "LinkedField",
                "name": "nounsOwned",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VotingPower",
                "kind": "LinkedField",
                "name": "tokensRepresented",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "currentlyActive": true
                    }
                  }
                ],
                "concreteType": "LiquidDelegationRepresentation",
                "kind": "LinkedField",
                "name": "liquidRepresentation",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Delegate",
                    "kind": "LinkedField",
                    "name": "proxy",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "liquidRepresentation(filter:{\"currentlyActive\":true})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VotingPower",
                "kind": "LinkedField",
                "name": "tokensOwned",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "bcb9a931ff0cdf97e7175c0bfeae8479",
    "id": null,
    "metadata": {},
    "name": "PageHeaderQuery",
    "operationKind": "query",
    "text": "query PageHeaderQuery(\n  $address: String!\n  $skip: Boolean!\n) {\n  delegate(addressOrEnsName: $address) @skip(if: $skip) {\n    ...ProfileDropDownFragment\n    id\n  }\n}\n\nfragment NounImageFragment on Noun {\n  tokenId\n  accessory\n  background\n  body\n  glasses\n  head\n}\n\nfragment NounResolvedNameFragment on ResolvedName {\n  address\n  name\n}\n\nfragment ProfileDropDownButtonFragment on Delegate {\n  address {\n    resolvedName {\n      ...NounResolvedNameFragment\n    }\n  }\n}\n\nfragment ProfileDropDownContentsFragment on Delegate {\n  address {\n    resolvedName {\n      name\n      address\n    }\n  }\n  statement {\n    __typename\n  }\n  nounsOwned {\n    id\n    ...NounImageFragment\n  }\n  tokensRepresented {\n    amount {\n      amount\n    }\n  }\n  nounsRepresented {\n    id\n    ...NounImageFragment\n  }\n  liquidRepresentation(filter: {currentlyActive: true}) {\n    proxy {\n      nounsRepresented {\n        id\n        ...NounImageFragment\n      }\n      id\n    }\n  }\n  tokensOwned {\n    amount {\n      amount\n    }\n  }\n}\n\nfragment ProfileDropDownFragment on Delegate {\n  ...ProfileDropDownButtonFragment\n  ...ProfileDropDownContentsFragment\n}\n"
  }
};
})();

(node as any).hash = "a79fe712454c0e42095004c824f34182";

export default node;
