/**
 * @generated SignedSource<<daba1ad62655ed5f25950c5f6962a4f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileDropDownContentsFragment$data = {
  readonly address: {
    readonly resolvedName: {
      readonly address: string;
      readonly name: string | null;
    };
  };
  readonly liquidRepresentation: ReadonlyArray<{
    readonly proxy: {
      readonly nounsRepresented: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"NounImageFragment">;
      }>;
    };
  }>;
  readonly nounsOwned: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"NounImageFragment">;
  }>;
  readonly nounsRepresented: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"NounImageFragment">;
  }>;
  readonly statement: {
    readonly __typename: "DelegateStatement";
  } | null;
  readonly tokensOwned: {
    readonly amount: {
      readonly amount: string;
    };
  };
  readonly tokensRepresented: {
    readonly amount: {
      readonly amount: string;
    };
  };
  readonly " $fragmentType": "ProfileDropDownContentsFragment";
};
export type ProfileDropDownContentsFragment$key = {
  readonly " $data"?: ProfileDropDownContentsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileDropDownContentsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "NounImageFragment"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TokenAmount",
    "kind": "LinkedField",
    "name": "amount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Noun",
  "kind": "LinkedField",
  "name": "nounsRepresented",
  "plural": true,
  "selections": (v0/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileDropDownContentsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResolvedName",
          "kind": "LinkedField",
          "name": "resolvedName",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DelegateStatement",
      "kind": "LinkedField",
      "name": "statement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Noun",
      "kind": "LinkedField",
      "name": "nounsOwned",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VotingPower",
      "kind": "LinkedField",
      "name": "tokensRepresented",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "currentlyActive": true
          }
        }
      ],
      "concreteType": "LiquidDelegationRepresentation",
      "kind": "LinkedField",
      "name": "liquidRepresentation",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Delegate",
          "kind": "LinkedField",
          "name": "proxy",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "liquidRepresentation(filter:{\"currentlyActive\":true})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VotingPower",
      "kind": "LinkedField",
      "name": "tokensOwned",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Delegate",
  "abstractKey": null
};
})();

(node as any).hash = "bec527667b8359e484f60876b4bc4fa9";

export default node;
