import { SupportType } from "@agora/common";
import React from "react";

import type { SupportTextProps } from "../../pages/DelegatePage/VoteDetailsContainer";
import type {
  AvailableVotingPower,
  VotingAddress,
} from "../../pages/PropHouseAuctionPage/usePropHouseAvailableVotingPower";
import { UpdateHistoryDialogContentFragment$key } from "../../pages/ProposalsPage/__generated__/UpdateHistoryDialogContentFragment.graphql";

import type { DialogDefinitions } from "./types";

export type DialogType =
  | DelegateDialogType
  | CastVoteDialogType
  | GiveFeedbackDialogType
  | GiveCandidateFeedbackDialogType
  | SponsorDialogType
  | CastAuctionVoteDialogType
  | ShowUpdateHistoryDialogType;

export type DelegateDialogType = {
  type: "DELEGATE";
  params: {
    targetAccountAddress: string;
  };
};

// todo: just use props for argument types instead of explicit param drilling
// todo: lazy load the dialog compoment
// todo: prefetch the data for the dialog

export type CastVoteDialogType = {
  type: "CAST_VOTE";
  params: {
    address: string;
    proposalId: number;
    reason: string;
    supportType: SupportTextProps["supportType"];
    onVoteSuccess: () => void;
  };
};

export type GiveFeedbackDialogType = {
  type: "GIVE_FEEDBACK";
  params: {
    address: string;
    proposalId: number;
    reason: string;
    supportType: SupportType;
    onFeedbackSuccess: () => void;
  };
};

export type GiveCandidateFeedbackDialogType = {
  type: "GIVE_CANDIDATE_FEEDBACK";
  params: {
    proposerAddress: string;
    slug: string;
    address: string;
    reason: string;
    supportType: SupportType;
    onFeedbackSuccess: () => void;
  };
};

export type SponsorDialogType = {
  type: "SPONSOR";
  params: {
    address: string;
    candidateId: string;
    reason: string;
    onSponsorSuccess: () => void;
  };
};

export type CastAuctionVoteDialogType = {
  type: "CAST_AUCTION_VOTE";
  params: {
    address: string;
    auctionId: number;
    pendingVotes: Map<number, number>;
    votingPower: AvailableVotingPower[];
    votingAddresses: VotingAddress[];
  };
};

export type ShowUpdateHistoryDialogType = {
  type: "SHOW_UPDATE_HISTORY";
  params: {
    fragmentRef: UpdateHistoryDialogContentFragment$key;
  };
};

export const dialogs: DialogDefinitions<DialogType> = {
  DELEGATE: React.lazy(() => import("../DelegateDialog/DelegateDialog")),
  CAST_VOTE: React.lazy(
    () =>
      import(
        "../../pages/ProposalsPage/ProposalActionPanel/Votes/CastVoteDialog"
      )
  ),
  GIVE_FEEDBACK: React.lazy(
    () =>
      import(
        "../../pages/ProposalsPage/ProposalActionPanel/Feedback/GiveFeedbackDialog"
      )
  ),
  GIVE_CANDIDATE_FEEDBACK: React.lazy(
    () =>
      import(
        "../../pages/ProposalsPage/ProposalActionPanel/CandidateFeedback/GiveCandidateFeedbackDialog"
      )
  ),
  SPONSOR: React.lazy(
    () =>
      import(
        "../../pages/ProposalsPage/ProposalActionPanel/Sponsorship/SponsorDialog"
      )
  ),
  CAST_AUCTION_VOTE: React.lazy(
    () => import("../../pages/PropHouseAuctionPage/AuctionCastVoteDialog")
  ),
  SHOW_UPDATE_HISTORY: React.lazy(
    () => import("../../pages/ProposalsPage/UpdateHistoryDialog")
  ),
};
