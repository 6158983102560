/**
 * @generated SignedSource<<36be9ff2a2b27b4300cdf45b4e9ed13f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NounImageFragment$data = {
  readonly accessory: number;
  readonly background: number;
  readonly body: number;
  readonly glasses: number;
  readonly head: number;
  readonly tokenId: string;
  readonly " $fragmentType": "NounImageFragment";
};
export type NounImageFragment$key = {
  readonly " $data"?: NounImageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NounImageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NounImageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tokenId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "background",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "glasses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "head",
      "storageKey": null
    }
  ],
  "type": "Noun",
  "abstractKey": null
};

(node as any).hash = "4b1da92bbae9ae874b84ac5edc57d21a";

export default node;
