/**
 * @generated SignedSource<<cce01c34c858c2db7b2bac4264d24753>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NounGridFragment$data = {
  readonly liquidRepresentation: ReadonlyArray<{
    readonly proxy: {
      readonly nounsRepresented: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"NounImageFragment">;
      }>;
    };
  }>;
  readonly nounsRepresented: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"NounImageFragment">;
  }>;
  readonly tokensRepresented: {
    readonly amount: {
      readonly amount: string;
    };
  };
  readonly " $fragmentType": "NounGridFragment";
};
export type NounGridFragment$key = {
  readonly " $data"?: NounGridFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NounGridFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Noun",
  "kind": "LinkedField",
  "name": "nounsRepresented",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NounImageFragment"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NounGridFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VotingPower",
      "kind": "LinkedField",
      "name": "tokensRepresented",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TokenAmount",
          "kind": "LinkedField",
          "name": "amount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "currentlyActive": true
          }
        }
      ],
      "concreteType": "LiquidDelegationRepresentation",
      "kind": "LinkedField",
      "name": "liquidRepresentation",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Delegate",
          "kind": "LinkedField",
          "name": "proxy",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "liquidRepresentation(filter:{\"currentlyActive\":true})"
    },
    (v0/*: any*/)
  ],
  "type": "Delegate",
  "abstractKey": null
};
})();

(node as any).hash = "e2fd95fc4b8c1c736c6efc7ae2cd3a87";

export default node;
